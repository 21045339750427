
import Auth from './auth/Auth.vue'
import Sidebar from './core/sidebar/index.vue'
import User from './core/user/index.vue'
import { AutomaticErrorPopup } from 'apptimizm-ui'
import { defineComponent, onMounted, Ref } from '@vue/runtime-core'
import { ref, watch, computed } from 'vue'
import { Notification } from './core/notifications/notifications'
import { TableWebSocket } from './core/web-socket'
import { store, mutations } from './store'
import { ProjectMeta } from './pages/projects/types'

export const isAuthorized: Ref<string> = ref('')

export default defineComponent({
  setup () {
    const tableWebSocket = new TableWebSocket()
    const projectMeta: ProjectMeta = new ProjectMeta()

    const isProjectSelected = computed(() => !!store.currentProject.id)

    onMounted(async () => {
      if (localStorage.auth) isAuthorized.value = localStorage.auth

      window.addEventListener('401 Unauthorized', () => {
        isAuthorized.value = ''
        localStorage.clear()
      })

      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = await projectMeta.getProject(localStorage.projectID)

          if (result?.id) {
            mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
            localStorage.projectID = result.id
          } else localStorage.projectID = ''
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }
    })

    watch(tableWebSocket.messages, v => {
      mutations.recordMessagesWS(v)
    }, { deep: true })

    return () => (
      <div>
        { isAuthorized.value ? (
          <div class="page-layout">
            <Sidebar/>
            <div class="content">
              <User/>
              <router-view/>
              <Notification/>
            </div>
          </div>
        ) : <Auth onAuth={() => { isAuthorized.value = localStorage.auth }}/> }
        <AutomaticErrorPopup/>
      </div>
    )
  }
})
